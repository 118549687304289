import { MenuFoldOutlined, MenuUnfoldOutlined } from '@ant-design/icons';
import { Layout, Menu } from 'antd';
import React, { useState } from 'react';
import { Link, withRouter } from 'react-router-dom';
import donationWidgetSelected from '../../../assets/donationWidget-selected.svg';
import donationWidget from '../../../assets/donationWidget.svg';
import dynamicPagesSelected from '../../../assets/dynamicPages-selected.svg';
import dynamicPages from '../../../assets/dynamicPages.svg';
import importUserSelected from '../../../assets/importUser-selected.svg';
import importUser from '../../../assets/importUser.svg';
import userSelected from '../../../assets/user-selected.svg';
import user from '../../../assets/user.svg';
import logo from '../../../assets/vector.png';
import { MODULES, ROUTES } from '../../../common/constants';
import './Sidebar.css';

const { Sider } = Layout;

function Sidebar({ location: { pathname }, history }) {
  const [collapsed, setCollapsed] = useState(false);
  const toggle = () => {
    setCollapsed(!collapsed);
  };

  const onMenuSelect = (e) => {
    history.push(e.key);
  };

  const selectedMenuKey = `/${pathname.split('/')[1]}`;

  return (
    <Sider trigger={null} collapsible collapsed={collapsed}>
      <div className="gx-layout-sider-header">
        <Link to="/" className="gx-site-logo">
          <img src={logo} alt="PRAGERU" />
        </Link>
      </div>
      <div className="gx-sidebar-content">
        <Menu
          theme="lite"
          mode="inline"
          selectedKeys={[`/${pathname.split('/')[1]}`]}
          defaultSelectedKeys={[ROUTES.USERS]}
          onSelect={onMenuSelect}
        >
          {/* commented for future use */}
          {/* <Menu.Item
            key={ROUTES.GROUPS}
            className="d-flex align-items-center"
            icon={
              <img
                src={selectedMenuKey === ROUTES.GROUPS ? groupSelected : group}
                alt="groups"
              />
            }
          >
            <span>{MODULES.GROUPS}</span>
          </Menu.Item>
          <Menu.Item
            key={ROUTES.THREADS}
            className="d-flex align-items-center"
            icon={
              <img
                src={
                  selectedMenuKey === ROUTES.THREADS ? threadSelected : thread
                }
                alt="threads"
              />
            }
          >
            <span>{MODULES.THREADS}</span>
          </Menu.Item> */}
          <Menu.Item
            key={ROUTES.USERS}
            className="d-flex align-items-center"
            icon={
              <img
                src={selectedMenuKey === ROUTES.USERS ? userSelected : user}
                alt="users"
              />
            }
          >
            <span>{MODULES.USERS}</span>
          </Menu.Item>
          <Menu.Item
            key={ROUTES.IMPORT_USERS}
            className="d-flex align-items-center"
            icon={
              <img
                src={
                  selectedMenuKey === ROUTES.IMPORT_USERS
                    ? importUserSelected
                    : importUser
                }
                alt="import users"
              />
            }
          >
            <span>{MODULES.IMPORT_USERS}</span>
          </Menu.Item>
          <Menu.Item
            key={ROUTES?.DYNAMIC_PAGES}
            className="d-flex align-items-center"
            icon={
              <img
                src={
                  selectedMenuKey === ROUTES?.DYNAMIC_PAGES
                    ? dynamicPagesSelected
                    : dynamicPages
                }
                alt="dynamic pages"
              />
            }
          >
            <span>{MODULES?.DYNAMIC_PAGES}</span>
          </Menu.Item>
          <Menu.Item
            key={ROUTES?.DONATION}
            className="d-flex align-items-center"
            icon={
              <img
                src={
                  selectedMenuKey === ROUTES?.DONATION
                    ? donationWidgetSelected
                    : donationWidget
                }
                alt="donation widget"
              />
            }
          >
            <span>{MODULES?.DONATION}</span>
          </Menu.Item>
        </Menu>
      </div>
      <div className="sider-footer-content">
        <div className="gx-linebar">
          <div className="gx-icon-btn icon" onClick={toggle}>
            {collapsed ? <MenuUnfoldOutlined /> : <MenuFoldOutlined />}
          </div>
        </div>
      </div>
    </Sider>
  );
}

export default withRouter(Sidebar);
