import { useAuth0 } from '@auth0/auth0-react';
import { Button } from 'antd';
import React from 'react';
import { withRouter } from 'react-router-dom';
import logo from '../../assets/logo.png';
import '../../styles/pages/login.less';

const Login = () => {
  const { loginWithRedirect } = useAuth0();
  return (
    <div className="login-wrapper">
      <div className="login-form-wrapper">
        <div className="header">
          <img src={logo} alt="PRAGERU" title="PRAGERU" />
        </div>

        <div className="body">
          <Button
            id="btn-signin"
            type="primary"
            onClick={() => loginWithRedirect()}
          >
            Sign In
          </Button>
        </div>
      </div>
    </div>
  );
};
export default withRouter(Login);
