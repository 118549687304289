import { useAuth0 } from '@auth0/auth0-react';
import React from 'react';
import { Route } from 'react-router-dom';
import { TOKEN } from '../../common/constants';
import Callback from './Callback';
import Login from './Login';

function GuardedRoute(props) {
  const { component: Component, path } = props;
  const { isAuthenticated } = useAuth0();
  // eslint-disable-next-line
  const isLoginUser = localStorage.getItem(TOKEN) ? localStorage.getItem(TOKEN) : null

  return !isLoginUser && isAuthenticated ? (
    <Route component={Callback} />
  ) : (
    <Route
      path={path}
      render={(prop) => {
        if (isLoginUser) return <Component {...prop} />;
        if (isAuthenticated !== undefined && !isAuthenticated) return <Login />;
        return <Component {...prop} />; //
      }}
    />
  );
}

export default GuardedRoute;
