import { gql } from '@apollo/client';

// Get User List
// eslint-disable-next-line import/prefer-default-export
export const GET_USERS = gql`
  query users($skip: Int, $limit: Int, $search: String, $group_id: ID) {
    users(
      filter: {
        skip: $skip
        limit: $limit
        search: $search
        group_id: $group_id
      }
    ) {
      count
      users {
        id
        first_name
        last_name
        middle_name
        phone_no
        email
        groups {
          id
          group_id
          title
          role
        }
      }
    }
  }
`;
