import { gql } from '@apollo/client';

// Create User
export const CREATE_USER = gql`
  mutation createUser(
    $first_name: String!
    $last_name: String!
    $middle_name: String
    $email: String!
    $is_super_admin: Boolean
    $phone_no: String
    $user_group_roles: [UserGroupRoles]
  ) {
    createUser(
      data: {
        first_name: $first_name
        last_name: $last_name
        middle_name: $middle_name
        email: $email
        is_super_admin: $is_super_admin
        phone_no: $phone_no
        user_group_roles: $user_group_roles
      }
    ) {
      message
    }
  }
`;

// Update User
export const UPDATE_USER = gql`
  mutation adminUpdate(
    $first_name: String
    $last_name: String
    $middle_name: String
    $is_super_admin: Boolean
    $phone_no: String
    $user_group_roles: [UserGroupRoles]
    $id: ID!
  ) {
    adminUpdate(
      data: {
        first_name: $first_name
        last_name: $last_name
        middle_name: $middle_name
        is_super_admin: $is_super_admin
        phone_no: $phone_no
        user_group_roles: $user_group_roles
      }
      id: $id
    ) {
      message
    }
  }
`;
