import { useAuth0 } from '@auth0/auth0-react';
import { Spin } from 'antd';
import React, { useEffect, useState } from 'react';
import { withRouter } from 'react-router-dom';
import { CLAIM_URL, TOKEN, USER } from '../../common/constants';
import { toast } from '../../common/utils';
import '../../styles/pages/login.less';

export default withRouter(function ({ history }) {
  const {
    isloading,
    isAuthenticated,
    getAccessTokenSilently,
    user
  } = useAuth0();
  const [isLoginUser, setIsLoginUser] = useState(null);

  if (isAuthenticated && !isLoginUser) {
    (async () => {
      // eslint-disable-next-line no-undef
      localStorage.setItem(USER, JSON.stringify(user));
      const accessToken = await getAccessTokenSilently();
      // eslint-disable-next-line
      localStorage.setItem(TOKEN, accessToken);
      if (user?.[CLAIM_URL]) {
        if (user?.[CLAIM_URL].role !== 'ADMIN') {
          toast({
            message: 'Access Denied',
            type: 'error'
          });
          history.replace('/logout');
        }
      } else {
        toast({
          message: 'Access Denied',
          type: 'error'
        });
        history.replace('/logout');
      }
      setIsLoginUser(accessToken);
    })();
  }

  useEffect(() => {
    // eslint-disable-next-line
    let getToken = localStorage.getItem(TOKEN)
    if (getToken) {
      setIsLoginUser(getToken);
    }
    if (
      !isloading &&
      isAuthenticated !== undefined &&
      isAuthenticated &&
      isLoginUser
    ) {
      history.replace('/');
    }
  }, [isloading, isAuthenticated, history, isLoginUser]);
  return (
    <div className="callback-wrapper">
      <Spin />
    </div>
  );
});
