import { DownOutlined } from '@ant-design/icons';
import { Avatar, Popover } from 'antd';
import React, { useContext, useState } from 'react';
import { withRouter } from 'react-router-dom';
import client from '../../../apollo';
import { AppContext } from '../../../AppContext';
import { CHANGE_PASSWORD } from '../../../modules/auth/graphql/Mutations';

const UserProfile = (props) => {
  const { getCurrentUser } = useContext(AppContext);
  const [visible, setVisible] = useState(false);

  const handleVisibleChange = (value) => {
    setVisible(value);
  };

  const changePasswordLink = async () => {
    try {
      await client.mutate({
        mutation: CHANGE_PASSWORD
      });
    } catch (error) {
      return error;
    } finally {
      setVisible(false);
    }
  };

  const { nickname } = getCurrentUser() || {};
  const userMenuOptions = (
    <ul className="gx-user-popover">
      <li onClick={() => changePasswordLink()}>Change Password</li>
      <li onClick={() => props.history.replace('/logout')}>Logout</li>
    </ul>
  );

  return (
    <div className="gx-avatar-row">
      <Popover
        className="d-flex flex-row align-items-center"
        placement="bottomRight"
        content={userMenuOptions}
        trigger="click"
        visible={visible}
        onVisibleChange={handleVisibleChange}
      >
        <Avatar className="gx-size-35 gx-pointer mr-2" alt="Avatar" />

        <span className="gx-avatar-name d-flex flex-row align-items-center">
          {nickname}
          <DownOutlined className=" gx-fs-xxs ml-2" />
        </span>
      </Popover>
    </div>
  );
};

export default withRouter(UserProfile);
