import { gql } from '@apollo/client';

// Update Group
// eslint-disable-next-line import/prefer-default-export
export const CHANGE_PASSWORD = gql`
  mutation changePassword {
    changePassword {
      message
    }
  }
`;
