import 'bootstrap/dist/css/bootstrap-grid.min.css';
import React from 'react';
import { BrowserRouter as Router, Route, Switch } from 'react-router-dom';
import App from './app/App';
import './assets/vendors/style';
import { ROUTES } from './common/constants';
import Callback from './modules/auth/Callback';
import GuardedRoute from './modules/auth/GuardedRoute';
import Logout from './modules/auth/Logout';

const Routes = () => {
  return (
    <Router>
      <Switch>
        <Route exact path={ROUTES.CALLBACK} component={Callback} />
        <Route exact path={ROUTES.LOGOUT} component={Logout} />
        <GuardedRoute path={ROUTES.MAIN} component={App} />
      </Switch>
    </Router>
  );
};
export default Routes;
