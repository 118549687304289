import { useAuth0 } from '@auth0/auth0-react';
import { Spin } from 'antd';
import React from 'react';
import { withRouter } from 'react-router-dom';
import '../../styles/pages/login.less';

const LogoutPage = () => {
  const { logout } = useAuth0();
  // eslint-disable-next-line no-undef
  localStorage.clear();
  // eslint-disable-next-line no-undef
  logout({ returnTo: window.location.origin });
  return (
    <div className="callback-wrapper">
      <Spin />
    </div>
  );
};
export default withRouter(LogoutPage);
